<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                {{ getLoginTitle() }}
              </h1>
            </div>
          </template>
          <v-form @submit="login">
            <v-card-text class="text-center">
              <v-text-field
                v-model="email"
                color="secondary"
                label="Login..."
                prepend-icon="mdi-email"
              />

              <v-text-field
                v-model="password"
                class="mb-8"
                color="secondary"
                label="Password..."
                prepend-icon="mdi-lock-outline"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @keyup.enter="login()"
                @click:append="showPassword = !showPassword"
              />
              <template v-if="errorMessage">
                <div class="text-center">
                  <v-alert type="error">
                    {{ errorMessage }}
                  </v-alert>
                </div>
              </template>

              <pages-btn
                large
                color=""
                depressed
                class="v-btn--text success--text"
                @click="login"
              >
                Login
              </pages-btn>
            </v-card-text>
          </v-form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
    <template v-if="networkError">
      <v-banner
        single-line
        elevation="10"
        color="error"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            Network Error. Check your internet connection.
          </div>
        </div>
      </v-banner>
    </template>
    <v-dialog
      v-model="showLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Logging In
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import titleMixin from '@/mixins/titleMixin.js'
  export default {
    name: 'PagesLogin',
    components: {
      PagesBtn: () => import('@/views/pages/components/Btn'),
    },
    mixins: [titleMixin],

    data: () => ({
      email: '',
      password: '',
      showPassword: false,
      errorMessage: '',
      networkError: false,
      showLoading: false,
      path: null,
      app: null,
    }),
    created () {
      this.setApp()
    },
    methods: {
      login () {
        this.showLoading = true
        this.path = this.$route.query.path
        this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.showLoading = false
            if (this.path) {
              this.$router.push({ path: this.path })
            } else {
              if (this.app === 'insights') {
                this.$router.push({ name: 'Home' })
              }
              if (this.app === 'contact') {
                this.$router.push({ name: 'Missions' })
              }
            }
          })
          .catch(error => {
            this.showLoading = false
            if (!error.response) {
              this.networkError = true
            } else {
              if (error.response.status === 401) {
                this.errorMessage = 'Invalid Login'
              } else {
                this.errorMessage = 'There was a problem authenticating you'
              }
            }
          })
      },
      setApp () {
        var host = window.location.host
        this.app = 'insights'
        if (new RegExp('dealerinsights').test(host)) {
          this.app = 'insights'
        }
        if (new RegExp('attemptingcontact').test(host)) {
          this.app = 'contact'
        }
        if (new RegExp('localhost').test(host)) {
          this.app = 'contact'
        }
        if (this.app === 'contact') {
          this.$router.push({ name: 'AC Login' })
        }
      },
      getLoginTitle () {
        var loginTitle
        if (this.app === 'insights') {
          loginTitle = 'Dealer Insights'
        }
        if (this.app === 'contact') {
          loginTitle = 'Attempting Contact'
        }
        return loginTitle
      },
    },
  }
</script>
