import Vue from 'vue'

import { mapGetters } from 'vuex'
export default {
  created () {
    var title
    var host = window.location.host
    if (new RegExp('dealerinsights').test(host)) {
      title = Vue.Constants.DI_TITLE
    }
    if (new RegExp('attemptingcontact').test(host)) {
      title = Vue.Constants.AC_TITLE
    }
    if (new RegExp('localhost').test(host)) {
      title = Vue.Constants.AC_TITLE
    }
    document.title = title
  },
  computed: {
    ...mapGetters(['appName']),
  },
}
